export default function Brush() {
	return (
		<svg
			version='1.1'
			id='Layer_1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 512.723 512.723'
            className="fill-accent h-10 w-10 md:h-12 md:w-12"
		>
			<g>
				<g>
					<g>
						<path
							d='M438.087,9.3c-2.92-2.907-6.338-5.195-10.161-6.803c-16.242-6.827-35.009,0.831-41.827,17.052L256.468,325.966
				c-4.567-0.687-9.241-1.046-13.996-1.046c-0.066,0-0.138,0-0.204,0c-51.679,0.111-93.632,42.245-93.521,93.923
				c0.042,19.692-7.586,38.221-21.481,52.175c-13.895,13.954-32.392,21.663-52.083,21.705c-5.523,0.012-9.99,4.499-9.979,10.021
				c0.012,5.516,4.486,9.979,10,9.979c0.007,0,0.015,0,0.022,0l167.445-0.358c51.679-0.111,93.632-42.244,93.521-93.923
				c-0.05-23.511-8.8-45.006-23.187-61.446L444.999,44.332C450.066,32.278,447.353,18.527,438.087,9.3z M316.192,418.486
				c0.087,40.65-32.914,73.793-73.565,73.88L132.991,492.6c21.817-17.22,35.82-43.913,35.756-73.799
				c-0.087-40.65,32.914-73.793,73.564-73.88c0.055,0,0.106,0,0.161,0C283.048,344.921,316.105,377.89,316.192,418.486z
				 M426.568,36.569L297.33,342.703c-6.53-4.73-13.69-8.639-21.328-11.576L404.527,27.32c2.554-6.075,9.576-8.939,15.647-6.386
				c1.437,0.604,2.716,1.458,3.801,2.539C427.443,26.925,428.458,32.071,426.568,36.569z'
						/>
						<path
							d='M198.368,392.174c-5.133-2.037-10.946,0.472-12.984,5.604c-2.879,7.253-4.331,14.916-4.313,22.775
				c0.012,5.516,4.486,9.978,10,9.978c0.007,0,0.015,0,0.022,0c5.522-0.012,9.99-4.499,9.978-10.022
				c-0.012-5.307,0.965-10.472,2.902-15.352C206.01,400.025,203.501,394.212,198.368,392.174z'
						/>
						<path
							d='M242.462,359.033c-0.044,0-0.091,0-0.135,0c-9.572,0.021-18.742,2.186-27.254,6.435
				c-4.942,2.466-6.948,8.472-4.481,13.413s8.471,6.948,13.413,4.481c5.728-2.859,11.907-4.315,18.366-4.329c0.03,0,0.06,0,0.091,0
				c11.021,0,21.389,4.281,29.202,12.06c7.834,7.801,12.162,18.185,12.185,29.241c0.012,5.516,4.486,9.979,10,9.979
				c0.007,0,0.015,0,0.022,0c5.522-0.012,9.99-4.499,9.979-10.021c-0.035-16.397-6.454-31.8-18.073-43.37
				C274.186,365.381,258.81,359.033,242.462,359.033z'
						/>
					</g>
				</g>
			</g>
		</svg>
	);
}
