export default function ReactIcon() {
	return (
		<svg
			width='50px'
			height='50px'
			viewBox='0 0 48 48'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g id='Layer_2' data-name='Layer 2'>
				<g id='invisible_box' data-name='invisible box'>
					<rect width='48' height='48' fill='none' />
				</g>
				<g id='Q3_icons' data-name='Q3 icons'>
					<g>
						<path
							d='M45.9,24c0-2.8-3-5.4-8-7.2l-.9-.3v-.4c1.1-5.5.3-9.6-2.2-11.1s-6.2-.1-10.2,3.4L24,9l-.2-.2h-.2C19.5,5,15.5,3.7,13,5.1s-3.2,5.3-2.2,10.4a4.4,4.4,0,0,1,.2,1l-1.1.4C5,18.6,2.1,21.2,2.1,24s3.2,5.6,8.2,7.4l.7.2a3.6,3.6,0,0,0-.2,1.1c-1,5-.1,8.8,2.2,10.2s6.5.1,10.5-3.4l.6-.5.8.8c2.8,2.4,5.6,3.7,7.8,3.7a4,4,0,0,0,2.2-.6c2.5-1.4,3.4-5.5,2.3-10.7V32a4.3,4.3,0,0,1-.1-.5h.3C42.7,29.6,45.9,26.9,45.9,24ZM43,24c0,1.2-2.2,3.3-6.5,4.7h-.2A36.7,36.7,0,0,0,34.4,24c.7-1.6,1.3-3.2,1.8-4.7l.8.2C41,20.9,43,22.8,43,24ZM26.8,37.6,26,37a43.1,43.1,0,0,0,3.2-4l5.1-.8c.1.2.1.4.2.5a17.5,17.5,0,0,1,.2,5.5h0a4.1,4.1,0,0,1-1.2,2.3C32.5,41.1,29.8,40.3,26.8,37.6Zm-1.3-4.4L24.1,35l-1.6-1.8Zm-13.7-14A31.9,31.9,0,0,0,13.7,24a33,33,0,0,0-1.9,4.9l-.6-.2a14.4,14.4,0,0,1-4.8-2.6A3.1,3.1,0,0,1,5,24c0-1.2,2-3.1,5.8-4.4Zm10.7-4.4L24,13.1l1.5,1.7h-3Zm11-7.3c1.1.6,1.7,3.6.8,8a.4.4,0,0,1-.1.3l-5-.8A43.1,43.1,0,0,0,26,11l.6-.5C29.8,7.7,32.4,6.9,33.5,7.5ZM16.8,18.1,16,19.4l-.7,1.3c-.3-.7-.5-1.4-.8-2.2ZM14.5,29.5c.3-.7.5-1.4.8-2.2l.7,1.3.8,1.3ZM31.2,24a33.7,33.7,0,0,1-1.7,3.2c-.6,1.1-1.3,2.1-1.9,3.1H20.4c-.7-1.1-1.4-2.1-1.9-3.1A31,31,0,0,1,16.8,24a24,24,0,0,1,1.7-3.2,30.5,30.5,0,0,1,1.9-3,43.3,43.3,0,0,1,7.2,0c.7,1,1.3,2,1.9,3A24,24,0,0,1,31.2,24Zm.8-4.6-.8-1.3,2.3.5a18,18,0,0,1-.8,2.1Zm-.8,10.5.8-1.3.7-1.4.9,2.3Zm-9.3-19H22l-3.2,4-5,.8c-.1-.3-.1-.5-.2-.8-.8-4.1-.2-6.9.8-7.4h0a1.3,1.3,0,0,1,.9-.2c1.4,0,3.8,1,6.5,3.4ZM13.6,33.2a2.9,2.9,0,0,1,.2-.9l5.1.7a33,33,0,0,0,3.2,4l-.2.2-.2.2A14.3,14.3,0,0,1,17,40.3a3.2,3.2,0,0,1-2.5.2C13.4,39.9,12.8,37.2,13.6,33.2Z'
							className="fill-secondary"
						/>
						<path
							d='M24,19.6A4.4,4.4,0,1,0,28.3,24,4.4,4.4,0,0,0,24,19.6Z'
							className="fill-secondary"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
}
