export default function Horse() {
	return (
		<svg
			version='1.1'
			id='_x32_'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 512 512'
            className="fill-accent h-10 w-10 md:h-12 md:w-12"
		>
			<g>
				<path
					className='st0'
					d='M379.987,204.198c-2.456-7.501-4.813-14.566-6.504-21.254c-1.703-6.702-2.74-12.982-2.734-19.03
		c0.014-6.464,1.116-12.664,4.028-19.346c8.406-19.214,11.436-38.125,11.43-55.186c-0.007-21.895-4.92-40.806-9.799-54.335
		c-4.893-13.528-9.785-21.703-10.05-22.146L358.588,0l-63.354,80.633h-78.25L153.637,0.007l-7.771,12.889
		c-0.264,0.449-5.157,8.624-10.049,22.152c-4.886,13.529-9.792,32.426-9.799,54.335c-0.006,17.061,3.024,35.972,11.43,55.179
		c2.806,6.438,3.928,12.816,3.935,19.604c0.052,12.863-4.371,27.25-9.323,42.582c-4.873,15.352-10.268,31.647-10.314,49.23
		c0,4.483,0.37,9.052,1.222,13.641c2.311,12.433,6.96,27.554,12.631,43.77c8.504,24.285,19.34,50.881,28.029,72.34
		c4.338,10.717,8.148,20.152,10.809,27.25c1.327,3.533,2.37,6.491,3.018,8.59c0.323,1.043,0.554,1.876,0.66,2.351l0.026,0.132
		c-0.013,0.694-0.079,1.81-0.211,3.275c-0.436,5.183-1.433,13.523-1.44,22.72c0.013,7.171,0.588,14.936,2.753,22.602
		c2.153,7.62,6.055,15.358,12.935,21.254c5.956,5.084,12.908,8.432,19.907,10.928c10.518,3.698,21.327,5.401,29.646,6.286
		c8.313,0.872,14.117,0.884,14.381,0.884c0.357-0.013,10.538,0,23.427-2.08c6.444-1.056,13.576-2.622,20.594-5.091
		c7.005-2.496,13.952-5.844,19.914-10.928c6.874-5.896,10.776-13.634,12.935-21.254c2.166-7.666,2.74-15.438,2.746-22.608
		c0-6.141-0.449-11.859-0.872-16.56c-0.211-2.35-0.422-4.444-0.561-6.154c-0.132-1.453-0.198-2.549-0.211-3.249
		c0.086-0.382,0.27-1.129,0.561-2.06c0.759-2.522,2.159-6.47,3.981-11.271c6.398-16.89,17.98-44.575,28.458-71.646
		c5.242-13.543,10.215-26.966,14.169-38.97c3.956-12.044,6.907-22.502,8.082-31.006c0.634-4.676,0.918-9.238,0.918-13.675
		C390.241,236.03,384.866,219.186,379.987,204.198z M213.636,101.426h85.618c0.31,0.515,0.614,1.057,0.918,1.664
		c2.12,4.278,3.711,10.591,4.609,17.378c0.924,6.788,1.228,14.05,1.228,20.482c0,4.186-0.125,8.009-0.284,11.146l-18.21-3.038
		l-20,43.314c-14.864-7.844-23.83-18.521-29.343-27.791c-3.136-5.289-5.098-10.102-6.253-13.523
		c-0.574-1.703-0.951-3.063-1.182-3.954l-0.224-0.978l-0.039-0.198l-1.757-9.613l-25.711,11.02
		c-0.819-3.117-1.539-7.138-1.532-11.898C201.52,126.305,203.944,114.638,213.636,101.426z M368.736,266.337
		c-0.495,3.704-1.704,9.006-3.48,15.186c-6.193,21.763-19.036,54.255-30.287,82.185c-5.632,13.991-10.875,26.88-14.757,36.982
		c-1.941,5.052-3.546,9.396-4.721,12.955c-0.588,1.796-1.076,3.381-1.459,4.886c-0.185,0.766-0.357,1.492-0.489,2.284
		c-0.132,0.805-0.258,1.638-0.264,2.832c0.007,1.776,0.133,3.513,0.298,5.448c0.495,5.725,1.366,13.232,1.366,20.944
		c0.007,5.982-0.548,11.99-1.961,16.929c-1.42,4.978-3.546,8.65-6.471,11.159c-3.129,2.707-7.877,5.203-13.297,7.091
		c-8.122,2.885-17.597,4.47-24.9,5.229c-3.651,0.384-6.774,0.575-8.94,0.674c-1.083,0.04-1.935,0.066-2.496,0.08l-0.621,0.013
		h-0.145h-0.046c-0.792,0-10.195-0.125-21.103-1.994c-5.447-0.924-11.257-2.298-16.461-4.173c-5.21-1.875-9.752-4.298-12.796-6.926
		c-2.919-2.502-5.045-6.174-6.471-11.152c-1.406-4.932-1.968-10.948-1.954-16.923c0-5.13,0.383-10.214,0.786-14.691
		c0.205-2.245,0.416-4.338,0.574-6.26c0.166-1.934,0.298-3.658,0.304-5.448c-0.014-1.287-0.152-2.192-0.304-3.076
		c-0.304-1.664-0.733-3.216-1.28-4.998c-2.034-6.55-5.85-16.269-10.703-28.346c-7.257-18.045-16.738-41.01-25.091-63.248
		c-8.345-22.185-15.542-43.842-18.157-58.13c-0.601-3.242-0.872-6.524-0.872-9.871c-0.053-13.093,4.371-27.566,9.323-42.886
		c4.86-15.338,10.261-31.522,10.314-48.926c0.007-9.138-1.604-18.626-5.685-27.929c-7.124-16.296-9.68-32.209-9.686-46.854
		c-0.006-18.758,4.265-35.405,8.564-47.269c0.535-1.486,1.07-2.899,1.598-4.226l43.407,55.252
		c-11.066,15.484-14.533,30.478-14.487,42.298c0.026,15.418,5.454,25.341,5.844,26.074l3.42,6.154l22.687-9.719
		c1.473,4.04,3.645,9.046,6.92,14.579c7.547,12.796,20.99,28.062,43.552,37.438l6.932,2.892l21.44-46.457l23.097,3.843l0.865-8.241
		c0.012-0.172,0.957-9.178,0.957-21.05c-0.007-9.231-0.548-20.152-2.688-30.293c-1.082-5.071-2.562-9.964-4.747-14.434
		c-0.594-1.202-1.255-2.384-1.968-3.526l43.05-54.79c4.734,11.945,10.221,30.36,10.168,51.476c0,14.638-2.556,30.544-9.687,46.854
		c-4.113,9.362-5.783,18.732-5.764,27.678c0,8.399,1.406,16.408,3.361,24.133c2.951,11.595,7.124,22.615,10.406,33.688
		c3.302,11.073,5.738,22.092,5.738,33.714C369.469,258.994,369.244,262.613,368.736,266.337z'
				/>
				<path
					className='st0'
					d='M228.176,441.839c-4.305,0-7.798,6.398-7.798,14.288c0,7.896,3.493,14.295,7.798,14.295
		c4.305,0,7.798-6.398,7.798-14.295C235.974,448.237,232.481,441.839,228.176,441.839z'
				/>
				<path
					className='st0'
					d='M284.049,441.839c-4.305,0-7.798,6.398-7.798,14.288c0,7.896,3.493,14.295,7.798,14.295
		c4.305,0,7.791-6.398,7.791-14.295C291.84,448.237,288.354,441.839,284.049,441.839z'
				/>
				<path
					className='st0'
					d='M180.781,242.726c-8.075,0-14.618,6.537-14.618,14.612c0,8.082,6.543,14.619,14.618,14.619
		c8.076,0,14.612-6.537,14.612-14.619C195.393,249.262,188.856,242.726,180.781,242.726z'
				/>
				<path
					className='st0'
					d='M331.443,242.726c-8.075,0-14.612,6.537-14.612,14.612c0,8.082,6.537,14.619,14.612,14.619
		c8.076,0,14.619-6.537,14.619-14.619C346.062,249.262,339.519,242.726,331.443,242.726z'
				/>
			</g>
		</svg>
	);
}
